import React from "react"
import Layout from "../components/Layout"

const Error = () => {
  return (
    <Layout>
      <main className="error-page">
        <section>
          <h1><span style={{ color: "#fff" }}>404</span></h1>
          <h3><span style={{ color: "#fff" }}>page not found</span></h3>
        </section>
      </main>
    </Layout>
  )
}
export default Error
