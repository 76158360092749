import React from "react"
import { Link } from "gatsby"
import logo from "../assets/images/FindAsync - Logo - White.svg"

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="nav-center">
        <div className="nav-header">
          <Link to="/">
            <img src={logo} alt="findasync text logo" />
          </Link>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
